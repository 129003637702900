// extracted by mini-css-extract-plugin
export var recentPost = "bV_mK";
export var recentPost__button = "bV_mX";
export var recentPost__postCard = "bV_mL";
export var recentPost__postCategory = "bV_mW";
export var recentPost__postClamp = "bV_mV";
export var recentPost__postDate = "bV_mQ";
export var recentPost__postDescriprion = "bV_mT";
export var recentPost__postImage = "bV_mN";
export var recentPost__postInfo = "bV_mP";
export var recentPost__postLink = "bV_mM";
export var recentPost__postStats = "bV_mR";
export var recentPost__postTitle = "bV_mS";
export var recentPost__title = "bV_mY";