import { FAQ } from '~/types/data-array'
import { DataContentProps } from '~/types/helper'

export const SchemaFaq = ({ dataContent }: DataContentProps<FAQ>) => {
  const stripHtml = (html: string): string =>
    html.replace(/<[^>]*>/g, '').trim()

  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: dataContent.map(({ question, answer }) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: stripHtml(answer),
      },
    })),
  }

  return JSON.stringify(schema)
}
