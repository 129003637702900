import { graphql, useStaticQuery } from 'gatsby'

import appleIpadMini from '~/assets/images/fav/apple-icon/apple-ipad-1536x2048.png?file'
import appleIpadPro10 from '~/assets/images/fav/apple-icon/apple-ipad-1668x2224.png?file'
import appleIpadPro11 from '~/assets/images/fav/apple-icon/apple-ipad-1668x2388.png?file'
import appleIpadPro12 from '~/assets/images/fav/apple-icon/apple-ipad-2048x2732.png?file'
import appleIphoneX from '~/assets/images/fav/apple-icon/apple-iphone-1125x2436.png?file'
import appleIphone678Plus from '~/assets/images/fav/apple-icon/apple-iphone-1242x2208.png?file'
import appleIphoneXsMax from '~/assets/images/fav/apple-icon/apple-iphone-1242x2688.png?file'
import appleIphone5 from '~/assets/images/fav/apple-icon/apple-iphone-640x1136.png?file'
import appleIphone678 from '~/assets/images/fav/apple-icon/apple-iphone-750x1334.png?file'
import appleIphoneXr from '~/assets/images/fav/apple-icon/apple-iphone-828x1792.png?file'
import appleTouch152 from '~/assets/images/fav/apple-icon/apple-touch-152x152.png?file'
import appleTouch167 from '~/assets/images/fav/apple-icon/apple-touch-167x167.png?file'
import appleTouch180 from '~/assets/images/fav/apple-icon/apple-touch-180x180.png?file'
import appleTouch512 from '~/assets/images/fav/apple-icon/apple-touch-512x512.png?file'
import {
  SchemaBlogPosting,
  SchemaBreadcrumbs,
  SchemaOnlineBusiness,
  SchemaOrganisation,
  SchemaPerson,
  schemaWebsite,
} from '~/components/seo-schemas'
import { WithRequired } from '~/types/helper'
import { SeoAuthor, SeoMeta } from '~/types/seo'

export interface SeoProps {
  title: string
  url: string
  urlImage: string
  description?: string | null
  authorSlug?: string | null
  authorName?: string
  lang?: string
  isPerson?: boolean
  isBlogPost?: boolean
  headline?: string | null
  datePublished?: string
  dateModified?: string
  image?: string
  structuredData?: string | null
  structuredDataVideo?: string | null
  structuredDataFAQPage?: string
  meta?: SeoMeta[]
  author?: SeoAuthor[]
  isBreadcrumbs?: boolean
  current?: string | null
  currentPath?: string | null
  secondLevel?: string | null
  secondLevelPath?: string | null
  thirdLevel?: string | null
  thirdLevelPath?: string | null
}

const Seo = ({
  title,
  url,
  urlImage,
  authorSlug,
  authorName,
  dateModified = '',
  datePublished = '',
  description = '',
  lang = 'en',
  meta = [],
  isPerson = false,
  isBlogPost = false,
  isBreadcrumbs = false,
  headline = '',
  author = [],
  image = '',
  structuredData = '',
  structuredDataVideo = '',
  structuredDataFAQPage = '',
  current = '',
  currentPath = '',
  secondLevel = '',
  secondLevelPath = '',
  thirdLevel = '',
  thirdLevelPath = '',
}: SeoProps) => {
  const { site } = useStaticQuery<Pick<Queries.Query, 'site'>>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          authorTwitter
        }
      }
    }
  `)

  const siteMetadata = site?.siteMetadata as WithRequired<
    Queries.SiteSiteMetadata,
    'title' | 'description' | 'author' | 'authorTwitter'
  >

  const metaDescription = description || siteMetadata?.description
  const metaData = [
    {
      content: 'IE=edge,chrome=1',
      'http-equiv': 'X-UA-Compatible',
    },
    {
      name: 'theme-color',
      content: '#fff',
    },
    {
      name: 'msapplication-TileColor',
      content: '#fff',
    },
    {
      name: 'msapplication-TileImage',
      content: '/static/ms-icon-144x144.png',
    },
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: url,
    },
    {
      property: 'og:title',
      content: `${title}`,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:image',
      content: `https://www.codica.com${urlImage}`,
    },
    {
      property: 'og:image:alt',
      content: `${title} | Codica`,
    },
    {
      name: 'twitter:site',
      content: '@codicacom',
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
    {
      name: 'twitter:creator',
      content: siteMetadata.authorTwitter,
    },
    {
      name: 'twitter:image',
      content: `https://www.codica.com${urlImage}`,
    },
    {
      name: 'apple-mobile-web-app-title',
      content: 'Codica - Custom Software Development Consultancy',
    },
    {
      name: 'mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'apple-mobile-web-app-status-bar-style',
      content: 'default',
    },
  ].concat(meta)

  return (
    <>
      <html lang={lang} />
      <title>{`${title} | Codica`}</title>

      {metaData.map(({ name, property, content }, index) => (
        <meta
          key={`${name || property}_${index}`}
          name={name}
          property={property}
          content={content}
        />
      ))}

      <link rel="canonical" href={url} />
      <link rel="apple-touch-icon" href={appleTouch512} />
      <link rel="apple-touch-icon" sizes="152x152" href={appleTouch152} />
      <link rel="apple-touch-icon" sizes="167x167" href={appleTouch167} />
      <link rel="apple-touch-icon" sizes="180x180" href={appleTouch180} />
      <link rel="apple-touch-icon" sizes="512x512" href={appleTouch512} />

      <link
        rel="apple-touch-startup-image"
        media="screen and (min-width: 320px) and (min-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        href={appleIphone5}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 375px) and (min-height: 667px) and (-webkit-device-pixel-ratio: 2)"
        href={appleIphone678}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 414px) and (min-height: 736px) and (-webkit-device-pixel-ratio: 3)"
        href={appleIphone678Plus}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 375px) and (min-height: 812px) and (-webkit-device-pixel-ratio: 3)"
        href={appleIphoneX}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 414px) and (min-height: 896px) and (-webkit-device-pixel-ratio: 2)"
        href={appleIphoneXr}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 414px) and (min-height: 896px) and (-webkit-device-pixel-ratio: 3)"
        href={appleIphoneXsMax}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 768px) and (min-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
        href={appleIpadMini}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 834px) and (min-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
        href={appleIpadPro10}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 834px) and (min-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
        href={appleIpadPro11}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 1024px) and (min-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
        href={appleIpadPro12}
      />

      <script type="application/ld+json">
        {JSON.stringify(schemaWebsite)}
      </script>
      <script type="application/ld+json">{SchemaOrganisation()}</script>
      <script type="application/ld+json">{SchemaOnlineBusiness()}</script>

      {isBreadcrumbs && (
        <script type="application/ld+json">
          {SchemaBreadcrumbs({
            current,
            currentPath,
            secondLevel,
            secondLevelPath,
            thirdLevel,
            thirdLevelPath,
          })}
        </script>
      )}

      {isBlogPost && (
        <script type="application/ld+json">
          {SchemaBlogPosting({
            description,
            url,
            headline,
            datePublished,
            dateModified,
            author,
            image,
          })}
        </script>
      )}

      {structuredData && (
        <script type="application/ld+json">
          {structuredData as string | undefined}
        </script>
      )}

      {structuredDataVideo && (
        <script type="application/ld+json">
          {structuredDataVideo as string | undefined}
        </script>
      )}

      {structuredDataFAQPage && (
        <script type="application/ld+json">{structuredDataFAQPage}</script>
      )}

      {isPerson && (
        <script type="application/ld+json">
          {SchemaPerson({
            title,
            description,
            authorSlug,
            authorName,
            url,
            urlImage,
          })}
        </script>
      )}
    </>
  )
}

export default Seo
