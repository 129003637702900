// extracted by mini-css-extract-plugin
export var socialBlock = "cX_v3";
export var socialBlock__icon = "cX_v8";
export var socialBlock__iconWrapper = "cX_v7";
export var socialBlock__icon_facebook = "cX_v9";
export var socialBlock__icon_instagram = "cX_wd";
export var socialBlock__icon_linkedin = "cX_wb";
export var socialBlock__icon_telegram = "cX_wf";
export var socialBlock__icon_tiktok = "cX_wg";
export var socialBlock__icon_twitter = "cX_wc";
export var socialBlock__iconsWrapper = "cX_v6";
export var socialBlock__sprite = "cX_v5";
export var socialBlock__title = "cX_v4";